body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'MontserratMedium';
  src: url('./views/components/Assets/font/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'RobotoBlack';
  src: url('./views/components/Assets/font/roboto-black.ttf') format('truetype');
}
